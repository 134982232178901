// ProjectPhotos.js
import React, { useState } from "react";
import Modal from "react-modal";
import "../pages/css/ProjectPhotos.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

// Set the app element for accessibility
Modal.setAppElement("#root");

const ProjectPhotos = () => {
  const imageUrl =
    "https://images.squarespace-cdn.com/content/v1/57ba706eb3db2bfb2051eecb/1584058270732-DYSGJ20JDYFJSAY05J64/image-asset.jpeg?format=2500w";

  const projects = [
    {
      title: "Multiple Screens",
      imgSrc: imageUrl,
      gallery: [imageUrl, imageUrl],
    },
    {
      title: "Dual 4k monitor Motorized",
      imgSrc: imageUrl,
      gallery: [imageUrl, imageUrl],
    },
    { title: "Data Center", imgSrc: imageUrl, gallery: [imageUrl, imageUrl] },
    {
      title: "IT Patch Panel",
      imgSrc: imageUrl,
      gallery: [imageUrl, imageUrl],
    },
    {
      title: "Home AV Solution",
      imgSrc: imageUrl,
      gallery: [imageUrl, imageUrl],
    },
    {
      title: "3 Motorized Desk",
      imgSrc: imageUrl,
      gallery: [imageUrl, imageUrl],
    },
    { title: "IT Rack", imgSrc: imageUrl, gallery: [imageUrl, imageUrl] },
    { title: "DVR System", imgSrc: imageUrl, gallery: [imageUrl, imageUrl] },
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (project) => {
    setSelectedProject(project);
    setCurrentImageIndex(0);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProject(null);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedProject.gallery.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedProject.gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <Header />

      <div className="conatiner-projects">
        <h1>Projects Gallery</h1>
        <div className="project-photos">
          <div className="photos-flex">
            {projects.map((project, index) => (
              <div
                key={index}
                className="photo-card"
                onClick={() => openModal(project)}
              >
                <img src={project.imgSrc} alt={project.title} />
                <h2>{project.title}</h2>
              </div>
            ))}
          </div>
        </div>
        {/* <button class="load-more-btn">Load More</button> */}

        {selectedProject && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Project Gallery"
            className="modal"
            overlayClassName="overlay"
          >
            <div className="modal-header">
              <h2>{selectedProject.title}</h2>
              <button onClick={closeModal} className="close-button">
                &times;
              </button>
            </div>
            <div className="modal-content">
              <button
                onClick={goToPreviousImage}
                className="nav-button previous"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-left"
                >
                  <line x1="19" y1="12" x2="5" y2="12" />
                  <polyline points="12 19 5 12 12 5" />
                </svg>
              </button>
              <img
                src={selectedProject.gallery[currentImageIndex]}
                alt={`${selectedProject.title} ${currentImageIndex + 1}`}
                className="modal-image"
              />
              <button onClick={goToNextImage} className="nav-button next">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </button>
            </div>
          </Modal>
        )}
      </div>
      <Footer/>
    </>
  );
};

export default ProjectPhotos;
