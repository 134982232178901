import "../pages/css/pricingpage.css";
import HeroPPImg from "../assets/consultation.svg";
import PlusIcom from "../assets/plus-alt-svgrepo-com.svg";
import { useEffect, useState } from "react";

export const PricingPlan = () => {
  return (
    <div className="PPcontainer">
      <h1 className="home-title">Pricing and Plans</h1>
      <div className="PPdecription-container">
        <div className="pp-left-description">
          <h2>Standard break fix rate $250 per hour</h2>
          <h3>
            Discounts apply based license
            and maintenance plan.
          </h3>
          <div className="card-pp">
            <p>Onsite / Offsite / Training</p>
            <p>2hr minimum deposit</p>
            <p>After hours x2 rate</p>
          </div>
        </div>
        <div className="pp-hero-container">
          <img src={HeroPPImg} alt="hero-img" />
        </div>
      </div>
      <h1 className="home-title">Monthly Support</h1>
      <div className="Ms-contaioner">
        <div className="ms-box">
          <p>Licensing</p>
        </div>
        <div className="plus-icon-container">
          <img src={PlusIcom} alt="plus" />
        </div>
        <div className="ms-box2">
          <p>Help</p>
        </div>
        <div className="plus-icon-container">
          <img src={PlusIcom} alt="plus" />
        </div>
        <div className="ms-box3">
          <p>Inital Startup</p>
        </div>
      </div>
      <div className="management-container">
        <h1 className="home-title">Management and Licensing</h1>
        <div className="ml-container">
          <div className="section-container">
            <section className="home-section r-padding">
              <div className="home-box bg-blur">
                <h3>Computer $32 per device</h3>

                <p className="home-text">
                  <ol>
                    <li>Antivirus</li>
                    <li>Backup (Files and Imaging)</li>
                    <li>Security and Support Tool</li>
                    <li>Enhanced System & Software Updates</li>
                  </ol>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>User $42 per person</h3>
                <p className="home-text">
                  <ol>
                    <li>Email and Office Apps</li>
                    <li>Email security (SOC)</li>
                    <li>Email and Onedrive Backup</li>
                    <li>Email Encryption</li>
                  </ol>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>IOT $22 per device</h3>
                <p className="home-text">
                  <ul>
                    <li>
                      Router - Firewall - WIFI - VPN - Switch - DVR - Printers -
                      Mobile - Smart Devices - IOT
                    </li>
                  </ul>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>Remote IT Support $32 per person / device</h3>
                <p className="home-text">
                  <ol>
                    <li>Unlimited Offsite Support (Phone, Text email)</li>
                    <li>Annual onsite visit included (1hr onsite visit)</li>
                  </ol>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>Priority Package $500 per site</h3>
                <p className="home-text">
                  <ol>
                    <li>Priority Support (phone, text, email)</li>
                    <li>Unlimited Onsite Support (up to 10hrs Monthly)</li>
                    <li>Loaner Equipment (desktops, laptop)</li>
                  </ol>
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CalculatorWidget = () => {
  useEffect(() => {
    // Create and append the Calconic script to the document
    const script = document.createElement("script");
    script.id = "calconic_";
    script.type = "text/javascript";
    script.async = true;
    script.dataset.calconic = true;
    script.src = "https://cdn.calconic.com/static/js/calconic.min.js";
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <h1 className="home-title">Calculate Your Plan</h1>
      <div
        className="sqs-block c-width code-block sqs-block-code"
        data-block-type="23"
        id="block-yui_3_17_2_1_1651117789608_13493"
        dangerouslySetInnerHTML={{
          __html: `
          <div class="sqs-block-content">
            <div class="calconic-calculator" data-calculatorid="6291ad09290c7d001ed7d09f">
              <div id="lumi-calculator-6291ad09290c7d001ed7d09f__wrapper">
                <div id="lumi-calculator-6291ad09290c7d001ed7d09f">
                  <div class="react-grid-layout calconic-elements with-animation check-breakpoint sm" style="height: 858px;">
                    <span data-ref="#1" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 71px; position: absolute; z-index: 1000; transform: translate(0px, 0px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r1" class="calconic-element calconic-input-wrapper">
                            <div class="calconic-element-label">
                              <label>Number of Computers - per device $32<i class="material-icons">info_outline</i></label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-input">
                                <span class="calconic--controled_input"><input type="text" id="#1" value="1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#2" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 71px; position: absolute; z-index: 1000; transform: translate(0px, 71px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r2" class="calconic-element calconic-input-wrapper">
                            <div class="calconic-element-label">
                              <label>Number of Email Logins - per person $42<i class="material-icons">info_outline</i></label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-input">
                                <span class="calconic--controled_input"><input type="text" id="#2" value="0"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#3" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 71px; position: absolute; z-index: 1000; transform: translate(0px, 142px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r3" class="calconic-element calconic-input-wrapper">
                            <div class="calconic-element-label">
                              <label>Number of Devices - per device $22<i class="material-icons">info_outline</i></label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-input">
                                <span class="calconic--controled_input"><input type="text" id="#3" value="0"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#4" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 89px; position: absolute; z-index: 1000; transform: translate(0px, 213px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r4" class="calconic-element calconic-input-wrapper">
                            <div class="calconic-element-label">
                              <label>Number of Help Desktop Users - per person / device $32<i class="material-icons">info_outline</i></label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-input">
                                <span class="calconic--controled_input"><input type="text" id="#4" value="0"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#11" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 71px; position: absolute; z-index: 1000; transform: translate(0px, 302px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r11" class="calconic-element calconic-input-wrapper">
                            <div class="calconic-element-label">
                              <label>Number of Locations<i class="material-icons">info_outline</i></label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-input">
                                <span class="calconic--controled_input"><input type="text" id="#11" value="0"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#5" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 58px; position: absolute; z-index: 1000; transform: translate(0px, 373px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r5" class="calconic-element calconic-check-wrapper">
                            <div class="calconic-element-label">
                              <label>Priority Pack - Per Location $500<i class="material-icons">info_outline</i></label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-checkbox js-has-checkbox">
                                <p>
                                  <label>
                                    <span class="rc-checkbox">
                                      <input type="checkbox" class="rc-checkbox-input" value="">
                                      <span class="rc-checkbox-inner"></span>
                                    </span>
                                    <span> Sites up to 10 users / devices</span>
                                  </label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#6" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 71px; position: absolute; z-index: 1000; transform: translate(0px, 431px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r6" class="calconic-element calconic-formula">
                            <div class="calconic-element-label">
                              <label>Estimated Monthly Cost</label>
                            </div>
                            <div class="calconic-element-controls">
                              <p>
                                <span class="p-p-fix">$</span>
                                <span id="formula-result-#6" class="result result-visible"><span>32</span></span>
                                <span class="p-p-fix"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#7" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 71px; position: absolute; z-index: 1000; transform: translate(0px, 502px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r7" class="calconic-element calconic-formula">
                            <div class="calconic-element-label">
                              <label>Estimated Startup Cost</label>
                            </div>
                            <div class="calconic-element-controls">
                              <p>
                                <span class="p-p-fix">$</span>
                                <span id="formula-result-#7" class="result result-visible"><span>300</span></span>
                                <span class="p-p-fix"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#8" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 95px; position: absolute; z-index: 1000; transform: translate(0px, 573px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r8" class="calconic-element calconic-text-wrapper">
                            <div class="calconic-element-label">
                              <label>Name</label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-textarea">
                                <input type="text" placeholder="Your text here" maxlength="5" value="rawaw">
                                <div class="text-count">
                                  <span> </span>
                                  <span>1</span>
                                  <span> </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#9" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 95px; position: absolute; z-index: 1000; transform: translate(0px, 668px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r9" class="calconic-element calconic-text-wrapper">
                            <div class="calconic-element-label">
                              <label>Company Name</label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-textarea">
                                <input type="text" placeholder="Your text here" maxlength="6" value="afeeaf">
                                <div class="text-count">
                                  <span> </span>
                                  <span>1</span>
                                  <span> </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span data-ref="#10" class="react-grid-item cssTransforms" style="visibility: visible; width: 414px; height: 95px; position: absolute; z-index: 1000; transform: translate(0px, 763px);">
                      <div class="element-visibility">
                        <div class="calconic-element__height-layer">
                          <div id="ref-r10" class="calconic-element calconic-text-wrapper">
                            <div class="calconic-element-label">
                              <label>Phone Number</label>
                            </div>
                            <div class="calconic-element-controls">
                              <div class="calconic-textarea">
                                <input type="text" placeholder="Mobile Number" maxlength="10" value="i907885674">
                                <div class="text-count">
                                  <span> </span>
                                  <span>1</span>
                                  <span> </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <div class="built-with-calconic">
                      Built with <a href="https://www.calconic.com/calculator-widgets" title="Interactive online calculator widgets for websites" target="blank_">CALCONIC_ Calculator Builder</a> |
                      <a href="https://www.calconic.com" title="Easily create and embed interactive calculator widgets to your website" target="blank_">Sign up for free!</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `,
        }}
      />
    </>
  );
};

export default CalculatorWidget;

export const ServicePlans = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Flat Rate Relationship Based IT Support",
      sections: [
        {
          heading: "Support Features",
          items: [
            "All inclusive remote support",
            "Helpdesk with text, email, and phone support",
            "Technology guidance, resource, and tips",
            "Technology aid and management for 3rd party software, hardware vendor",
            "Antivirus, system maintenance and support tools",
            "Secure remote access",
            "Computer specs and monthly technology report",
            "Technology reference manual",
            "Yearly reoccurring bills audit",
            "Microsoft Office suite",
            "Loaner equipment",
            "Daily offsite data backup",
            "Onedrive / Sharepoint and email backup",
            "Email powered by Microsoft O365",
            "Email security",
          ],
        },
      ],
    },
    {
      title: "Add-Ons for Monthly Plans",
      sections: [
        {
          heading: "Computer Rental",
          items: [
            "Laptop (14 or 15inch) - $60/month",
            "Desktop (includes 27+ inch monitor) - $75/month",
            "New replacement every 2 years",
          ],
        },
        {
          heading: "VOIP Phone",
          items: [
            "Physical Phone - $50/user",
            "Voicemail to email",
            "Find me follow me service ring to cell phone",
            "Text Messaging",
          ],
        },
      ],
    },
    {
      title: "Networking Solutions",
      sections: [
        {
          heading: "WIFI Solutions",
          items: [
            "WIFI $600 + $300 Onboard - two nodes and controller (up to 3000sq ft)",
            "Multiple SSID names and guest mode",
            "Wifi6 with WPA3 Security",
            "Seamless 2.4G and 5G AX/AC/N/G/B Bands",
            "Cloud Management",
          ],
        },
        {
          heading: "Additional Networking",
          items: [
            "Wire Drop - $225 per drop",
            "LCD TV Mounting - $500 includes mounting hardware and FireTV device programming and review",
            "Site Firewall Hardware - $500 + $300 Onboard",
            "Network Cabinet - $2000",
          ],
        },
      ],
    },
    {
      title: "Computers and Laptops",
      sections: [
        {
          heading: "Desktops",
          items: [
            "HP Elitedesk G8 - $900 + $300 setup/onboarding - i5 256SSD 8gb RAM",
            "Apple - $699+ (Special Order)",
          ],
        },
        {
          heading: "Laptops",
          items: [
            "14 inch - $500 + $300 setup/onboarding - i5 256 SSD 8gb RAM",
            "15 inch - $600 + $300 setup/onboarding - i5 256 SSD 8gb RAM",
            "17 inch - $600 + $300 setup/onboarding - i3 256 SSD 8gb RAM",
            "Apple - $999+ (Special Order)",
          ],
        },
      ],
    },
  ];

  return (
    <div className="tabs">
      <div className="tab-titles">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-title ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabs[activeTab].sections.map((section, i) => (
          <div key={i} className="section">
            <h3 className="section-heading">{section.heading}</h3>
            <ul className="section-items">
              {section.items.map((item, j) => (
                <li key={j} className="section-item">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
