import "../pages/homepage.css";
import AOS from "aos";
import "aos/dist/aos.css";
import heroImg from "../assets/hero-img.svg";
import Tooltip from "@mui/material/Tooltip";
import SaasLogo from "../assets/SaaS-Alerts-Logo.png";
import { useEffect } from "react";
export const HomePageHero = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
    window.scroll(0, 0);
  }, []);

  return (
    <div className="hero-container">
      <video
        src="https://cdn.pixabay.com/video/2023/10/10/184489-873483996_small.mp4"
        loop
        muted
        autoPlay
        className="video-player"
        playsInline
        preload="metadata"
      >
        Your browser does not support the video tag.
      </video>
      <div className="dark-overlay"></div>
      <div className="hero-txt-container-carousel">
        <h1>We Make Technology Work For You</h1>
        <a href="https://consultlm.syncromsp.com/bookings" className="book-btn">
          Book Now
        </a>
      </div>
    </div>
  );
};

export const HomeCont = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
  }, []);
  return (
    <div className="home-container">
      <h1 className="home-title">Welcome to I.T. Autopilot</h1>
      <div className="section-container">
        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img src={SaasLogo} alt="logo" />
            <h2 className="home-section-title">Unlimited Remote Support</h2>
            <p className="home-text">
              Get round-the-clock support with comprehensive technology
              guidance. Our managed IT services and monthly reports ensure that
              your technology runs smoothly.
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31"
              alt="logo"
            />
            <h2 className="home-section-title">Helpdesk</h2>
            <p className="home-text">
              We're here to help through every channel - phone, remote login,
              chat, and text. Your issues are resolved swiftly, keeping your
              operations uninterrupted.
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.bitdefender.com/adobe/dynamicmedia/deliver/dm-aid--ff62d212-3a6d-4993-841c-7458d12cdf2f/bitdefender-blue-logo-png.png?width=1920&preferwebp=true&quality=85"
              alt="logo"
            />
            <h2 className="home-section-title">Virus / Malware Protection</h2>
            <p className="home-text">
              Protect your systems with on-demand and scheduled scans, and a
              robust software firewall. Keep threats at bay with our advanced
              protection.
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.titanhq.com/EE/img/logos/titan-hq-logo.svg"
              alt="logo"
            />
            <h2 className="home-section-title">Email</h2>
            <p className="home-text">
              Experience seamless email services powered by Microsoft M365.
              Efficient, reliable, and integrated for your business needs.
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1056.36 228.17"
            >
              <defs>
                <style></style>
              </defs>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    class="ninja-logo-1"
                    d="M971.12,79.85q-8.34,7.32-10.19,20h61.38q-1.86-12.45-10.19-19.9a29.51,29.51,0,0,0-20.39-7.46Q979.46,72.51,971.12,79.85Zm76.33,78Q1029.87,179,996.12,179c-14,0-26.48-2.91-37.27-8.68a61.1,61.1,0,0,1-25-24.08Q925,130.92,925,111.36a70,70,0,0,1,8.7-34.79,62.35,62.35,0,0,1,23.82-24.18,70.45,70.45,0,0,1,67.07-.39,59.27,59.27,0,0,1,23.3,23.8c5.63,10.39,8.44,22.37,8.44,36.05,0,.5-.26,3.9-.67,10.22H961.41a30.87,30.87,0,0,0,12,19.35c6.31,4.72,14.18,7,23.61,7a45.61,45.61,0,0,0,17.26-3,30.17,30.17,0,0,0,3.54-1.7Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M113.16,60.12Q127.4,74.78,127.4,103.6v75.23H91.27V109.46q0-15.63-6.49-23.33T66,78.44q-13.66,0-21.77,8.91t-8.11,26.5v65H0V58.41L34.52,41.92V62.81A47.68,47.68,0,0,1,52.35,50a59.21,59.21,0,0,1,23.4-4.53Q98.91,45.46,113.16,60.12Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M154.29,58.43V178.84h36.12V41.94Z"
                  ></path>
                  <polygon
                    class="ninja-letters"
                    points="190.41 0.03 190.41 30.93 154.29 47.41 154.29 16.52 190.41 0.03"
                  ></polygon>
                  <polygon
                    class="ninja-letters"
                    points="371.21 16.55 371.21 47.45 407.32 30.9 407.32 0 371.21 16.55"
                  ></polygon>
                  <path
                    class="ninja-letters"
                    d="M331.36,60.12Q345.6,74.78,345.6,103.6v75.23H309.47V109.46q0-15.63-6.49-23.33t-18.76-7.69q-13.67,0-21.77,8.91t-8.11,26.5v65H218.2V58.41l34.52-16.49V62.81A47.68,47.68,0,0,1,270.55,50,59.21,59.21,0,0,1,294,45.46Q317.11,45.46,331.36,60.12Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M407.33,42.26V178.58q0,23-12.39,36.29t-34.64,13.3q-19,0-28.94-7.32l9.71-27.6q6.26,4.63,15.29,4.62c4.64,0,8.25-1.57,10.89-4.75s3.94-7.94,3.94-14.29v-120Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M561.36,58.41V178.83H526.84V163.68q-13.44,17.12-38.91,17.1a62,62,0,0,1-31.85-8.3,58,58,0,0,1-22.35-23.7q-8.12-15.37-8.11-35.66t8.11-35.66a58,58,0,0,1,22.35-23.69,62,62,0,0,1,31.85-8.31q23.85,0,37.29,15.88V41.92Zm-44.48,81.21q9-9.88,9-26.5t-9-26.5a29.42,29.42,0,0,0-22.7-9.89q-13.89,0-22.93,9.89t-9,26.5q0,16.62,9,26.5t22.93,9.89A29.42,29.42,0,0,0,516.88,139.62Z"
                  ></path>
                  <path
                    class="ninja-logo-1"
                    d="M624.48,170.28a82.82,82.82,0,0,1-31.74-31.64q-11.46-20.14-11.46-45.3T592.74,48A82.86,82.86,0,0,1,624.48,16.4Q644.75,4.93,670,4.92T715.39,16.4A83.83,83.83,0,0,1,747.13,48q11.58,20.14,11.58,45.31t-11.58,45.3a83.79,83.79,0,0,1-31.74,31.64Q695.25,181.76,670,181.76T624.48,170.28Zm71.46-29.19a48.84,48.84,0,0,0,18.19-19.42,59.66,59.66,0,0,0,6.6-28.33A59.66,59.66,0,0,0,714.13,65a48.93,48.93,0,0,0-18.19-19.43,51.84,51.84,0,0,0-51.89,0A48.84,48.84,0,0,0,625.87,65a59.55,59.55,0,0,0-6.61,28.33,59.55,59.55,0,0,0,6.61,28.33,48.75,48.75,0,0,0,18.18,19.42,51.84,51.84,0,0,0,51.89,0Z"
                  ></path>
                  <path
                    class="ninja-logo-1"
                    d="M891.79,60.12Q906,74.78,906,103.6v75.23H869.89V109.46q0-15.63-6.48-23.33t-18.76-7.69q-13.67,0-21.77,8.91t-8.12,26.5v65H778.63V58.41l34.52-16.49V62.81A47.68,47.68,0,0,1,831,50a59.18,59.18,0,0,1,23.4-4.53Q877.52,45.46,891.79,60.12Z"
                  ></path>
                </g>
              </g>
            </svg>
            <h2 className="home-section-title">Data Backup</h2>
            <p className="home-text">
              Ensure your data is safe with standard user folder backups and
              monthly imaging. Your crucial information is always secure.
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://cdn.prod.website-files.com/6356c441ce34029b327802bf/63eebaf42d2925e34203e31a_logo-white.svg"
              alt="logo"
            />
            <h2 className="home-section-title">Flat Rate Pricing</h2>
            <p className="home-text">
              Simple and transparent pricing. Get access to all our services
              without unexpected costs.
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.passwordboss.com/wp-content/uploads/2022/06/Password-Boss-Logo.png"
              alt="logo"
            />
            <h2 className="home-section-title">Vendor Management</h2>
            <p className="home-text">
              Streamline your vendor interactions with our comprehensive
              management for internet, phone, copier, and software. Faster
              problem-solving guaranteed.
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.autoelevate.com/wp-content/uploads/2018/10/AutoElevate-Logo-by-CyberFOX-060122b-e1654603999434.png"
              alt="logo"
            />
            <h2 className="home-section-title">Computer Monitoring</h2>
            <p className="home-text">
              Keep your systems in top shape with continuous monitoring of
              tasks, background services, RAM, and hard drives. Optimization
              included!
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
  }, []);

  const servicesList = [
    {
      title: "Managed Whole Office Technology Solutions and Packages",
      description:
        "Comprehensive IT solutions tailored for your entire office.",
    },
    {
      title: "Server Support and Management",
      description: "Expert server support to ensure your systems run smoothly.",
    },
    {
      title: "Technology Audit",
      description: "Thorough assessment of your current technology landscape.",
    },
    {
      title: "Internet Security",
      description: "Protect your network with advanced security solutions.",
    },
    {
      title: "Network Management",
      description:
        "Efficient management of your entire network infrastructure.",
    },
    {
      title: "Wi-Fi Management",
      description: "Optimize and manage your Wi-Fi for better connectivity.",
    },
    {
      title: "Phone System Management",
      description: "Seamless management of your business phone systems.",
    },
    {
      title: "Data Storage and Backup Solutions",
      description: "Secure and reliable data storage and backup options.",
    },
    {
      title: "Software Monitoring and Patching",
      description:
        "Keep your software up-to-date and secure with our services.",
    },
    {
      title: "Unified Threat Management",
      description:
        "Comprehensive threat protection for your entire IT environment.",
    },
    {
      title: "Web Domain Management",
      description: "Manage your web domains with ease and efficiency.",
    },
    {
      title: "Vendor Management",
      description:
        "Streamline your vendor relationships with our management services.",
    },
    {
      title: "Unified Communication Solutions",
      description:
        "Integrate fax, voicemail, and email into one cohesive system.",
    },
    {
      title: "Tablets and Mobile Devices Support",
      description:
        "Support for all your mobile devices, including tablets and smartphones.",
    },
    {
      title: "Windows / Mac / Linux",
      description: "Comprehensive support for all major operating systems.",
    },
    {
      title: "AV / Automation - Home and Business Solutions",
      description:
        "Automate your home or business with our advanced solutions.",
    },
    {
      title: "CCTV Camera Systems",
      description:
        "Monitor your premises with our state-of-the-art CCTV systems.",
    },
    {
      title: "Internet Faxing",
      description: "Send and receive faxes via the internet with ease.",
    },
    {
      title: "Office365 and Google G-Suite",
      description:
        "Get the most out of Office365 and Google G-Suite with our support.",
    },
    {
      title: "Web Security",
      description: "Protect your website with our advanced security measures.",
    },
    {
      title: "Email Security",
      description:
        "Secure your email communications with our top-tier solutions.",
    },
    {
      title: "File Sharing & Sync",
      description:
        "Efficient and secure file sharing and synchronization solutions.",
    },
  ];

  return (
    <>
      <h1 className="home-title-wp">Services</h1>

      <div className="hero-container d-flex">
        <div className="img-conatiner-hero" data-aos="fade-right">
          <img className="hero-img" src={heroImg} alt="heroimg" />
        </div>
        <div className="hero-txt-container">
          <h1>Technology Services</h1>
          <p>
            Empowering businesses with cutting-edge technology solutions
            tailored to drive innovation and efficiency. From IT support to
            advanced automation and bespoke development, our comprehensive
            services are designed to meet the unique needs of your organization.
            Partner with us to leverage the power of technology and achieve your
            goals with confidence.
          </p>
        </div>
      </div>

      <div className="services-container" data-aos="zoom-in-up">
        {servicesList.map((service, index) => (
          <Tooltip title={service.description} key={index} arrow>
            <div className="service-card">
              <p className="service-title">{service.title}</p>
            </div>
          </Tooltip>
        ))}
      </div>
    </>
  );
};

export const Quicklinks = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
  }, []);

  const quicklinksList = [
    {
      name: "Quick Support",
      url: "https://consultlm.rmmservice.com/connect/#/9201551896",
    },
    {
      name: "Ios Remote App",
      url: "https://apps.apple.com/us/app/ninjaone-assist/id6451313359",
    },
    {
      name: "Windows Support",
      url: "https://rmm.syncromsp.com/dl/msi/MjAwMTgwNTUtMTYwODQ3MDk0NC01NDIyMC03NDYyMg==",
    },
    { name: "Password Manager", url: "https://www.passwordboss.com/download" },
    {
      name: "Mac Support Basic",
      url: "https://production.kabutoservices.com/desktop/macos/setup?token=3f6e16b0-a57e-48de-8cf2-e9a933174c59",
    },
    {
      name: "Ninja Remote Viewer",
      url: "https://resources.ninjarmm.com/development/ninjacontrol/5.3.3267/ncinstaller-x64.exe",
    },
    {
      name: "Support Pro",
      url: "https://consultlm.rmmservice.com/installer/1526719042",
    },
  ];
  return (
    <div className="quicklinks-container">
      <h1 className="home-title-wp">Tools Links</h1>
      <div>
        {quicklinksList.map((link, index) => (
          <a href={link.url} target="blank" key={index}>
            <button className="quicklink-button">{link.name}</button>
          </a>
        ))}
      </div>
    </div>
  );
};
