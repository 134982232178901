import { useEffect } from "react";

const useScrollLock = (isLocked) => {
  useEffect(() => {
    if (isLocked) {
      // Lock scroll position and prevent background issues
      const scrollY = window.scrollY;
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
      document.body.style.backgroundAttachment = "fixed";
    } else {
      // Unlock scroll position and restore background
      const scrollY = document.body.style.top;
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.backgroundAttachment = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [isLocked]);
};

export default useScrollLock;
