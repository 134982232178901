import React from "react";
import "./tab.css";
import { Link } from "react-router-dom";

const Tab = () => {
  return (
    <div className="tab-container">
      <Link to="/"><div className="tab-btn">Home</div></Link>
      <Link to="/about"><div className="tab-btn">About</div></Link>
      <Link to="/pricing"><div className="tab-btn">Pricing & Plans</div></Link>
      <Link to="/projects"><div className="tab-btn">Projects</div></Link>
      <Link to="/client-portal"><div className="tab-btn">Client Portal</div></Link>
    </div>
  );
};

export default Tab;
