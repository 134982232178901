import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Logo from "../assets/logo.png";
import {
  HomeCont,
  HomePageHero,
  Quicklinks,
  Services,
} from "../components/HomepageComp";
import Footer from "../components/Footer";

export const HomePage = () => {
  const [showRipple, setShowRipple] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRipple(false);
    }, 2000);
    // this logic should only run at once

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showRipple ? (
        <RippleImg />
      ) : (
        <>
          <Header />
          <HomePageHero />
          <HomeCont />
          <Services />
          <Quicklinks />
          <Footer />
        </>
      )}
    </div>
  );
};

export const RippleImg = () => {
  return (
    <div className="rippleEffect">
      <img className="rippleImg" src={Logo} alt="ripple" />
    </div>
  );
};
