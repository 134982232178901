import React from "react";
import "./css/clientportal.css"; // External CSS for styling
import Footer from "../components/Footer";
import Header from "../components/Header";

const ClientPortal = () => {
  return (
    <>
      <Header />
      <div className="client-portal">
        {/* Schedule NOW Section */}
        <div className="portal-section parent-portal schedule-now">
          <div className="content box-tab">
            <img
              src="https://www.svgrepo.com/show/78514/meeting-date.svg"
              alt="Schedule NOW"
              className="portal-icon"
            />
            <div className="text-content-cards">
              <h2>Schedule NOW</h2>
              <p>Book us now and let us help you to get running.</p>
              <a href="https://consultlm.syncromsp.com/bookings">
                <button className="portal-btn">Book Your Slot</button>
              </a>
            </div>
          </div>
          <div className="portal-section remote-access">
            <div className="content box-tab">
              <img
                src="https://www.svgrepo.com/show/409792/ticket.svg"
                alt="Remote Access"
                className="portal-icon"
              />
              <div className="text-content-cards">
                <h2>Service Ticket</h2>
                <p>
                  Raise a service ticket and get immediate help from our
                  experts.
                </p>
                <a href="https://consultlm.syncromsp.com/check_ins">
                  <button className="portal-btn">Raise a Ticket </button>
                </a>
              </div>
            </div>
          </div>
          {/* Account Login Section */}
          <div className="portal-section account-login">
            <div className="content box-tab">
              <img
                src="https://www.svgrepo.com/show/527781/login-3.svg"
                alt="Account Login"
                className="portal-icon"
              />
              <div className="text-content-cards">
                <h2>Account Login</h2>
                <p>
                  Access your account to manage your services and stay connected
                  with ease.
                </p>
                <button className="portal-btn">Sign In</button>
              </div>
            </div>
          </div>

          {/* Remote Access Section */}
          <div className="portal-section remote-access">
            <div className="content box-tab">
              <img
                src="https://www.svgrepo.com/show/366419/remote-desktop.svg"
                alt="Remote Access"
                className="portal-icon"
              />
              <div className="text-content-cards">
                <h2>Remote Access</h2>
                <p>Connect to your work environment securely from anywhere.</p>
                <a href="https://consultlm.rmmservice.com/">
                  <button className="portal-btn">Connect Now</button>
                </a>
              </div>
            </div>
          </div>

          {/* Teams Meeting Section */}
          <div className="portal-section zoom-meeting">
            <div className="content box-tab">
              <img
                src="https://www.logo.wine/a/logo/Microsoft_Teams/Microsoft_Teams-Logo.wine.svg"
                alt="Teams Meeting"
                className="portal-icon"
              />
              <div className="text-content-cards">
                <h2>Teams Meeting</h2>
                <p>Join with us seamlessly with a single click.</p>
                <a href="https://teams.microsoft.com/l/channel/19%3A5uDvk5ktXtgeXps_brWEhcg2fTxgpCJRZnwkOpxY1Ps1%40thread.skype/Website%20Leads?groupId=85b3cead-81b8-4dc8-8e0e-474f17138f66&tenantId=aecdb3cf-6aad-43c5-a8ec-243900f5e937&ngc=true&allowXTenantAccess=true">
                  <button className="portal-btn">Join Meeting</button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Downloads Section */}
        <div className="portal-section downloads">
          <div className="content">
            <div className="text-content">
              <h2>Downloads</h2>
              <img
                src="https://www.svgrepo.com/show/249808/browser-download.svg"
                alt="Downloads"
                className="portal-icon"
              />

              <p>Get the tools and software you need, all in one place.</p>
              <ul className="downloads-list">
                <li>
                  <a href="#O365Invitation">O365 Invitation</a>
                </li>
                <li>
                  <a href="#NinjaRemoteMac">Ninja Remote Mac</a>
                </li>
                <li>
                  <a href="#NinjaDesktopMac">Ninja Desktop Mac</a>
                </li>
                <li>
                  <a href="#NinjaRemoteWin">Ninja Remote Win</a>
                </li>
                <li>
                  <a href="#SplashtopMac">Splashtop Mac</a>
                </li>
                <li>
                  <a href="#NinjaDesktopWin">Ninja Desktop Win</a>
                </li>
                <li>
                  <a href="#SplashtopWin">Splashtop Win</a>
                </li>
                <li>
                  <a href="#Teamviewer15Win">Teamviewer15 Win</a>
                </li>
                <li>
                  <a href="#Teamviewer15Mac">Teamviewer15 Mac</a>
                </li>
                <li>
                  <a href="#20h9update">20h9 update</a>
                </li>
                <li>
                  <a href="#F1HelpSupportTool">F1 Help Support Tool</a>
                </li>
                <li>
                  <a href="#NinjaImageRestore">Ninja Image Restore</a>
                </li>
                <li>
                  <a href="#O36564bit">O365 64bit</a>
                </li>
                <li>
                  <a href="#O36532Bit">O365 32Bit</a>
                </li>
                <li>
                  <a href="#MicrosoftTeamsForWork">Microsoft Teams for Work</a>
                </li>
                <li>
                  <a href="#Ninite">Ninite</a>
                </li>
                <li>
                  <a href="#AdobeCloud">Adobe Cloud</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Tech Login Section */}
        <div className="portal-section tech-login">
          <div className="content">
            <div className="text-content">
              <h2>Tech Login</h2>
              <img
                src="https://www.svgrepo.com/show/444574/tech-circuit.svg"
                alt="Tech Login"
                className="portal-icon"
              />
              <p>
                Your gateway to managing and monitoring systems efficiently.
              </p>
              <ul className="tech-login-list height">
                <a
                  href="https://consultlm.rmmservice.com/#/auth/login"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Ninja</li>
                </a>
                <a
                  href="https://consultlm.syncromsp.com/users/sign_in"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Syncro</li>
                </a>
                <a
                  href="https://cumulus.sherweb.com/partners/lm-consulting"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Share Web</li>
                </a>
                <a
                  href="https://securitytrails.com/"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>DNS History</li>
                </a>
                <a
                  href="https://consultlm.huducloud.com"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Hudu</li>
                </a>
                <a
                  href="https://qrcode.tec-it.com/en/Wifi"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>QR Generator</li>
                </a>
                <a
                  href="https://download.teamviewer.com/download/TeamViewer_Setup.exe"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Teamviewer full Installer</li>
                </a>
                <a
                  href="https://cloud.gravityzone.bitdefender.com/"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Bitdefender</li>
                </a>
                <a
                  href="https://omada.tplinkcloud.com/login"
                  className="quicklink-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Omada</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClientPortal;
