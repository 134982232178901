import React from "react";
import Header from "../components/Header";
import {
  PricingPlan,
  ServicePlans,
} from "../components/PricingpageComp";
import Footer from "../components/Footer";

const PricingPage = () => {
  return (
    <>
      <Header />
      <PricingPlan />
      <ServicePlans />
      <Footer/>
    </>
  );
};

export default PricingPage;
